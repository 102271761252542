import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function SwiperModalAds({ images, initialSlide }) {
  return (
    <Swiper
      loop
      style={{
        '--swiper-navigation-color': '#64748b',
        '--swiper-pagination-color': '#64748b',
      }}
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      navigation={true}
      effect={'fade'}
      keyboard={true}
      modules={[Navigation, Pagination, Keyboard, Mousewheel]}
      className="mySwiper"
      initialSlide={initialSlide}
    >
      {images.gallery.map((image, index) => (
        <SwiperSlide key={index}>
          <TransformWrapper>
            <TransformComponent>
              <img src={image.url} alt="img" className="m-auto h-full object-contain" />
            </TransformComponent>
          </TransformWrapper>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SwiperModalAds
