import { Link } from "react-router-dom";
import Logo from './../images/logo.png';
import Instagram from './../images/instagram.svg';
import {useTranslation} from "react-i18next";

const Footer = () => {
    const { t } = useTranslation(); // Hook for translations

    const handleClick = () => {
            window.scrollTo(0, 0);
    };

    const data = [
        {title: t("exhibition"),  href: 'exhibition'},
        {title: t("about"), href: 'about'},
        {title: t("works"), href: 'works'},
        {title: t("gallery"), href: 'gallery'},
        {title: t("contacts"), href: 'contacts'},
    ]



    return (
    <div className="px-4 md:px-[20px] lg:px-[20px] xl:px-[100px] py-[20px] lg:py-[80px] flex-col flex items-center">
      <div className="flex flex-col items-center">
        <Link to="/">
          <img alt="svg" src={Logo} />
        </Link>
      </div>
      <nav className="mt-7 border-t border-[#E1C17B] py-7 w-full">
        <ul className="flex flex-col md:flex-row gap-x-20 lg:gap-x-24">
          {data.map(({ href, title }) => (
            <li className='m-2' key={title}>
              <Link to={href} onClick={handleClick}>
                <p className={'text-darkBg dark:text-lightBg text-sm text-center'}>{title}</p>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex flex-col-reverse md:flex-row justify-between w-full">
        <div className="text-white flex gap-x-4 text-nowrap gap-x-7 items-start md:items-center justify-between mt-10 md:mt-0">
          <div className="text-darkBg dark:text-lightBg flex flex-col md:flex-row gap-x-4">
            <p>Copyright . 2023 SARGIS BABAYAN</p>
          </div>
          <Link to="https://www.instagram.com/sargis.babayann/" target='_blank'>
            <img alt="svg" src={Instagram} />
          </Link>
        </div>
        <div className="text-[#E1C17B] flex-col flex">
          <Link target="_blank" to={`tel:+37477083388`}>(+374) 77 083388</Link>
          <Link target="_blank" to={`mailto:Sargis.bab@gmail.com`}>Sargis.bab@gmail.com</Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
