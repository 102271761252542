import classNames from "../../utils";
import { useTranslation } from "react-i18next";

const BlockTree = ({ image, biography }) => {
    const { t } = useTranslation(); // Hook for translations

    return (
    <div className="mt-16 lg:mt-28 flex flex-col-reverse xl:flex-row justify-between pl-5 md:pl-20 pr-5 md:pr-32 gap-x-4">
        <img className="mt-20 lg:mt-0 max-h-[500px]" src={image} alt="png" />
        <div className="flex flex-col max-w-[325px] md:max-w-[610px] min-w-[325px] md:min-w-[510px]">
          <h1 className="text-subTitle text-lg md:text-2xl text-start md:text-center md:text-start md:text-4xl font-bold font-custom">{t("exhibition")}</h1>
          <p
            className={classNames(
              'myHtml text-xl text-darkBg dark:text-lightBg mt-6 md:mt-12 leading-10 font-customFamily font-normal max-w-[325px] md:max-w-[610px] min-w-[325px] md:min-w-[510px]'
            )}
            dangerouslySetInnerHTML={{ __html: biography || '' }}
          />
        </div>
    </div>
  )
}

export default BlockTree