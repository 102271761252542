import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { baseApiUrl } from "../service/base-url";
import SwiperAdsMobile from "../components/Swiper/SwiperAdsMobile";
import SwiperAds from "../components/Swiper/SwiperAds";

const Work = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${baseApiUrl}/works/${id}`, {
          headers: { 'Content-Type': 'application/json' }
        })
        const dataRes = response.data
        if (dataRes) {
          setProduct(dataRes.data)
        }
      } catch (error) {
        toast.error('Please try again.')
        console.error('Error:', error)
      }
    }

    fetchProduct();
  }, [id]);


  if (!product) return <p>Loading...</p>;

  return (
        <div
          className='bg-white sm:px-8 flex justify-center'>
            <div className="mt-2 block md:hidden w-full">
              <SwiperAdsMobile works={product} ads={product} images={product} />
            </div>
            <div className="md:w-1/2">
              <div className="hidden w-full md:block">
                <SwiperAds images={product} />
              </div>
            </div>
      </div>
  );
};

export default Work;
