import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from "../../utils";

export default function Modal({
  children,
  isOpenModal,
  onClose,
  title = '',
  bgWhite = true,
}) {
  return (
    <Transition.Root show={isOpenModal} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto py-10">
          <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative max-w-screen-lg transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:mx-4 md:w-3/4',
                  bgWhite && 'bg-white px-4 pb-4 pt-5 sm:p-6',
                  !bgWhite && 'mt-20 lg:mt-0 2xl:mt-20',
                )}
              >
                <div>
                  <p className="my-2 block text-lg text-gray-700">{title}</p>
                  <div>{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
