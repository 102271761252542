import React, { useCallback, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectFade,
  Thumbs,
} from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import 'swiper/css/thumbs'
import ModalForAdsMobile from './ModalForAdsMobile'
import SwiperModalAdsMobile from '../Swiper/SwiperModalAdsMobile'
import { useWindowSize } from "../../useWindowSize";

const SwiperAdsMobile = ({ images = [], allData, ads }) => {
  const { width } = useWindowSize()
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [modalIndex, setModalIndex] = useState(0)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const onClose = useCallback(() => setIsOpenModal(false), [setIsOpenModal])
  const onOpenModal = (index) => {
    setModalIndex(index)
    setIsOpenModal(true)
  }

  return (
    <div className="rounded-lg p-[15px] shadow-myShadow">
      <Swiper
        style={{
          '--swiper-navigation-color': '#64748b',
          '--swiper-pagination-color': '#64748b',
        }}
        loop
        effect={'fade'}
        navigation={true}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        thumbs={{ swiper: thumbsSwiper }}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, EffectFade, Thumbs]}
        className="mySwiper"
      >
        {images?.gallery.map((image, index) => (
          <SwiperSlide key={index}>
            <img
              src={image.url}
              onClick={() => onOpenModal(index)}
              alt="img"
              className="m-auto h-full cursor-pointer"
            />
          </SwiperSlide>
        ))}

        <ModalForAdsMobile
          onClose={onClose}
          onOpenModal={onOpenModal}
          isOpenModal={isOpenModal}
        >
          <SwiperModalAdsMobile
            onCloseArrow={onClose}
            allData={allData}
            ads={ads}
            images={images}
            initialSlide={modalIndex}
          />
        </ModalForAdsMobile>
      </Swiper>
      <Swiper
        style={{
          marginTop: '11px',
        }}
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={width >= 1024 ? 4 : 3}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[EffectFade, Navigation, Thumbs]}
      >
        {images.gallery.map((image, index) => (
          <SwiperSlide key={index} className="cursor-pointer rounded-sm">
            <img
              src={image.url}
              alt=""
              className="h-20 w-24 cursor-pointer rounded-xl object-cover"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export default SwiperAdsMobile
