import { baseApiUrl, getDynamicData } from "../service/base-url";
import { useEffect, useState } from "react";
import TabPanelExhibition from "../components/TabPanelExhibition";
import axios from "axios";
import toast from "react-hot-toast";
import ExhibitionComponent from "../components/ExhibitionComponent";
import {useSelector} from "react-redux";

const Exhibitions  = () => {
  const [exhibitionData, setExhibitionData] = useState()
    const language = useSelector((state) => state.language.language);

  const type = [
    {name: 'SOLO', id: 1},
    {name: 'GROUP', id: 2}
  ]

  useEffect(() => {
    getDynamicData('exhibitions', setExhibitionData, null, language)
  }, [])

  const sortWorks = async (category_id) => {
    try {
      const url = category_id === 'all'
        ? `${baseApiUrl}/exhibitions`
        : `${baseApiUrl}/exhibitions?type=${category_id}`;

      const response = await axios.get(url, {
        headers: { 'Content-Type': 'application/json' ,'x-localization': language, },
      })
      const dataRes = response.data
      if (dataRes) {
        setExhibitionData(dataRes.data)
      }
    } catch (error) {
      toast.error('Please try again.')
      console.error('Error:', error)
    }
  }

  return (
    <div className="px-5 md:px-20">
      <TabPanelExhibition categories={type} sortWorks={sortWorks} />
      <ExhibitionComponent gallery={exhibitionData} />
    </div>
  )
}

export default Exhibitions
