import { useEffect, useState } from "react";
import { getDynamicData } from "../service/base-url";
import BlockOne from "../components/AboutPage/BlockOne";
import BlockTwo from "../components/AboutPage/BlockTwo";
import BlockTree from "../components/AboutPage/BlockThree";
import BlockFore from "../components/AboutPage/BlockFore";
import { useSelector } from "react-redux";

const About  = () => {
  const [aboutData, setAboutData] = useState()
  const language = useSelector((state) => state.language.language);

  useEffect(() => {
    getDynamicData('about', setAboutData, null, language)
  }, [language])

    return (
    <div>
      <BlockOne data={aboutData} />
      <BlockTwo image={aboutData?.biography_image} biography={aboutData?.biography} />
      <BlockTree image={aboutData?.exhibitions_image} biography={aboutData?.exhibitions} />
      <BlockFore biography={aboutData?.awards} />
    </div>
  )
}

export default About
