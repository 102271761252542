import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(LanguageDetector) // Detect user language
    .use(initReactI18next) // Bind i18n to React
    .init({
        resources: {
            en: {
                translation: {
                    biography: "BIOGRAPHY",
                    about_sargis: "ABOUT SARGIS BABAYAN",
                    exhibition: "EXHIBITION",
                    about: "ABOUT",
                    works: 'WORKS',
                    gallery: 'GALLERY',
                    contacts: 'CONTACTS',
                    created_date: 'Created Date',
                    awards: 'AWARDS AND DIPLOMAS',
                    view_more: 'VIEW MORE',
                    call: 'CALL FOR ADVICE NOW!',
                    say: 'SAY HELLO!',
                    name: 'Name',
                    email: 'Email',
                    phone: 'Phone Number',
                    message: 'Message',
                    send: 'Send us a message',
                    find: 'FIND OUT MORE'

                },
            },
            hy: {
                translation: {
                    biography: "ԿԵՆՍԱԳՐՈՒԹՅՈՒՆ",
                    about_sargis: "ՍԱՐԳԻՍ ԲԱԲԱՅԱՆԻ ՄԱՍԻՆ",
                    exhibition: "ՑՈՒՑԱՀԱՆԴԵՍՆԵՐ",
                    about: "ԿԵՆՍԱԳՐՈՒԹՅՈՒՆ",
                    works: 'ԱՇԽԱՏԱՆՔՆԵՐ',
                    gallery: 'ՊԱՏԿԵՐԱՍՐԱՀ',
                    contacts: 'ԿՈՆՏԱԿՏՆԵՐ',
                    created_date: 'Ամսաթիվ',
                    awards: 'ՄՐՑԱՆԱԿՆԵՐ ԵՎ ԴԻՊԼՈՄՆԵՐ',
                    view_more: 'ԴԻՏԵԼ ԱՎԵԼԻՆ',
                    call: 'ԶԱՆԳԱՀԱՐԵՔ ՀԻՄԱ',
                    say: 'ԱՍԵՔ ԲԱՐԵՎ',
                    name: 'Անուն',
                    email: 'էլփոստի հասցե',
                    phone: 'Հեռախոսահամար',
                    message: 'Հաղորդագրություն',
                    send: 'Ուղարկեք հաղորդագրություն',
                    find: 'ԳՏԵՔ ԱՎԵԼԻՆ'
                },
            },
        },
        fallbackLng: "en", // Default language
        debug: true, // Enable console logs (disable in production)
        interpolation: {
            escapeValue: false, // React handles escaping
        },
    });

export default i18n;
