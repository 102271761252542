import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TabPanelExhibition = ({ categories, sortWorks }) => {
    const { t } = useTranslation(); // Hook for translations
  const [activeTab, setActiveTab] = useState(null);

  const handleTabClick = (item) => {
    setActiveTab(item.id);
    sortWorks(item.name);
  };

  return (
    <div className="flex flex-col lg:flex-row justify-between mt-4 pb-4 border-b">
      <p onClick={() => handleTabClick({name: 'all'})} className="text-darkBg dark:text-lightBg text-4xl lg:text-5xl cursor-pointer">{t("exhibition")}</p>
      <div className="flex items-center gap-x-3 md:gap-x-4 lg:gap-x-8 mt-4 lg:mt-0">
        {categories.map((item, index) => (
          <div
            onClick={() => handleTabClick(item)}
            className={`cursor-pointer pb-1 ${
              activeTab === item.id ? 'border-b-2 border-darkBg dark:border-lightBg font-bold' : ''
            }`}
            key={index}
          >
            <p className="text-darkBg dark:text-lightBg font-custom tracking-wide">{item.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TabPanelExhibition;
