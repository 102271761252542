import classNames from "../../utils";
import { useTranslation } from "react-i18next";

const BlockFore = ({ biography }) => {
    const { t } = useTranslation(); // Hook for translations

  return (
    <div className="mt-28 flex justify-between px-5 md:px-20">
        <div className="flex flex-col">
          <h1 className="text-subTitle text-2xl text-center md:text-start md:text-4xl font-bold font-custom">{t("awards")}</h1>
          <p
            className={classNames(
              'myHtml text-xl text-darkBg dark:text-lightBg mt-6 md:mt-12 leading-10 font-customFamily font-normal'
            )}
            dangerouslySetInnerHTML={{ __html: biography || '' }}
          />
        </div>
    </div>
  )
}

export default BlockFore