import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { baseApiUrl, fetchDataPaginationBase, getDynamicData } from "../service/base-url";
import TabPanel from "../components/TabPanel";
import ShowProducts from "../components/ShowProducts";
import axios from "axios";
import toast from "react-hot-toast";
import Arrow from "../images/arrow-right.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Works  = () => {
  const location = useLocation();
  const [categories, setCategories] = useState([])
  const [works, setWorks] = useState([])
  const [links, setLinks] = useState(null)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const scrollYRef = useRef(0);
  const language = useSelector((state) => state.language.language);
  const { t } = useTranslation(); // Hook for translations

    useEffect(() => {
        const storedCategory = sessionStorage.getItem("selectedCategory");
        const categoryId = storedCategory ? parseInt(storedCategory, 10) : location?.state?.item.id ?? 6;

        setCurrentPage(1);  // Reset pagination
        setWorks([]);       // Clear previous works before fetching new ones
        sortWorks(categoryId);
    }, [language]);  // Ensure it runs when language changes



  useEffect(() => {
    getDynamicData('work_categories', setCategories, null, language)
  }, [language])

  const sortWorks = async (category_id) => {
    try {
        sessionStorage.setItem("selectedCategory", category_id);

        setWorks([]);  // Ensure we clear previous data before fetching new data
        setLinks(null);
      const url = category_id === 0
        ? `${baseApiUrl}/works`
        : `${baseApiUrl}/works?category_id=${category_id}`;

      const response = await axios.get(url, {
        headers: { 'Content-Type': 'application/json', 'x-localization': language, },
      });

      const dataRes = response.data;
      if (dataRes) {
        setWorks(dataRes.data);
        setLinks(dataRes.links);
      }
    } catch (error) {
      toast.error('Please try again.');
      console.error('Error:', error);
    }
  };

  const fetchDataPagination = async (page) => {
    await fetchDataPaginationBase(
      page,
      (newData, newLinks) => {
        setWorks((prevData) => [...prevData, ...newData])
        setLinks(newLinks)
      },
      setLinks,
      null,
      'works',
        language
    )
  }

  const handleLoadMore = async () => {
    if (isLoadingMore) return;
    setIsLoadingMore(true);

    scrollYRef.current = window.scrollY;

    const nextPage = currentPage + 1;
    await fetchDataPagination(nextPage);
    setCurrentPage(nextPage);

    setTimeout(() => {
      window.scrollTo(0, scrollYRef.current);
    }, 0);

    setIsLoadingMore(false);
  };

  return (
    <div className="px-5 xl:px-20">
      <TabPanel id={location?.state?.item.id} sortWorks={sortWorks} categories={categories} />
      <ShowProducts works={works} />
      {links?.next_page_url && (
        <div
          onClick={handleLoadMore}
          className="flex justify-center items-center py-14 cursor-pointer"
        >
          <button className='text-darkBg dark:text-lightBg py-2 px-4 my-2 rounded text-xl'>
            {isLoadingMore ? 'Loading...' : t("view_more")}
          </button>
          <img alt="png" src={Arrow} />

        </div>
      )}
    </div>
  )
}

export default Works
