import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from "../../utils";

export default function ModalForAdsMobile({ children, isOpenModal, onClose }) {
  return (
    <Transition.Root show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[99999] h-full overflow-y-hidden" //100vh
        onClose={onClose}
      >
        <div className="fixed absolute inset-0 bg-black" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex min-h-full items-center justify-center">
            <Dialog.Panel
              className={classNames(
                'top-[20px] w-[95%] max-w-screen-lg transform overflow-hidden rounded-lg text-left shadow-xl',
                'mt-100 mb-100',
              )}
            >
              <div>{children}</div>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}
