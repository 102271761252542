import GoTo from "../GoTo";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const BlockFive = ({ data }) => {
    const { t } = useTranslation(); // Hook for translations
  return (
    <div className="h-full mb-[64px] px-5 md:px-20">
      <p className="text-4xl md:text-6xl text-center font-custom my-8 md:my-16 text-darkBg dark:text-lightBg">{t('exhibition')}</p>
      <div className="flex flex-col md:flex-row gap-x-16 space-y-4 md:space-y-0 justify-center">
        {data && data.slice(0, 2).map((exhibition, index) => (
          <div key={index} className=" w-full md:w-1/2">
            <Link to={`/exhibition/${exhibition.id}`}>
              <div>
                <div className="px-6 md:px-14 py-6 md:py-12 bg-[#1B1A18] mb-6">
                  <div>
                    <img className="w-full max-h-[160px] min-h-[160px] md:max-h-[320px]" alt="png" src={exhibition.image} />
                  </div>
                </div>
                <p className="text-darkBg dark:text-lightBg text-2xl font-custom">{exhibition.name}</p>
                <p className="text-darkBg dark:text-lightBg mt-4 text-lg font-custom">{exhibition.date}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <GoTo title={t('find')} to="/works"/>
    </div>
  )
}

export default BlockFive