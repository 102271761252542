import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Mousewheel, Navigation } from 'swiper/modules'
import WhiteArrowIcon from '../../images/arrowleftWhite.svg'
import CloseIcon from '../../images/CLOS.svg'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function SwiperModalAdsMobile({ images, initialSlide, onCloseArrow }) {
  const [currentSlide, setCurrentSlide] = useState(initialSlide + 1)

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex + 1)
  }

  let agent
  function getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/windows phone/i.test(userAgent)) {
      agent = 1
      return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      agent = 2
      return 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      agent = 3
      return 'iOS'
    }

    return 'unknown'
  }

  useEffect(() => {
    getMobileOperatingSystem()
  }, [])

  return (
    <div className="relative">
      <div>
        <div className="top-0 z-50 flex w-full items-center justify-between bg-black px-3 pb-6">
          <img
            onClick={() => onCloseArrow()}
            className="inline-block h-6 w-7"
            src={WhiteArrowIcon}
            alt="image"
          />
          <p className="text-lg text-white">
            {currentSlide}/{images.gallery.length}
          </p>

          <img
            onClick={() => onCloseArrow()}
            className="inline-block h-8 w-8"
            src={CloseIcon}
            alt="image"
          />
        </div>
        <Swiper
          loop
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          effect={'fade'}
          keyboard={true}
          modules={[Navigation, Keyboard, Mousewheel]}
          className="mobile"
          initialSlide={initialSlide}
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          {images.gallery.map((image, index) => {
            return (
              <SwiperSlide key={index}>
                <TransformWrapper>
                  <TransformComponent>
                <img src={image.url} alt="img" className="h-auto w-full object-cover" />
                  </TransformComponent>
                </TransformWrapper>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default SwiperModalAdsMobile
