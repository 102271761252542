import BlockOne from "../components/HomePage/BlockOne";
import BlockTwo from "../components/HomePage/BlockTwo";
import { useEffect, useState } from "react";
import { getDynamicData } from "../service/base-url";
import BlockThree from "../components/HomePage/BlockThree";
import BlockFore from "../components/HomePage/BlockFore";
import BlockFive from "../components/HomePage/BlockFive";
import ContactUsBlock from "../components/ContactUsBlock";
import { Helmet } from "react-helmet";
import {useSelector} from "react-redux";

const Home  = () => {
  const [homeData, setHomeData] = useState()
    const language = useSelector((state) => state.language.language);

  useEffect(() => {
    getDynamicData('home', setHomeData, null, language)
  }, [language])

  return (
    <>
      <Helmet>
        <title>Բարի գալուստ Սարգիս Բաբայանի պաշտոնական կայք:</title>
        <meta
          name="description"
          content="Բարի գալուստ Սարգիս Բաբայանի պաշտոնական կայք: Հայտնի հայ քանդակագործ, որի արվեստը համադրություն է ավանդույթի և ժամանակակից ոճերի: Դիտեք նրա աշխատանքները:"
        />
        <meta
          name="keywords"
          content="Սարգիս Բաբայան - Հայ քանդակագործ | Պաշտոնական կայք"
        />
      </Helmet>

      <BlockOne data={homeData?.home} />
      <BlockTwo data={homeData?.biography} />
      <BlockThree data={homeData?.works}/>
      <BlockFore data={homeData?.home} />
      <BlockFive data={homeData?.exhibitions} />
      <ContactUsBlock />
    </>
  )
}
export default Home
