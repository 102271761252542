import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import Arrow from '../images/chevron-down.svg'
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../redux/languageSlice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Language() {
  const [currentLng, setCurrentLng]  = useState('')
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.language.language);
    const { i18n } = useTranslation();

  useEffect(() => {
    setCurrentLng(currentLanguage)
  },[currentLanguage])

  const handleLanguageChange = (lang) => {
      i18n.changeLanguage(lang);
      dispatch(setLanguage(lang));
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center gap-x-2">
          <span className="font-semibold">{currentLng === 'en' ? 'ENG' : 'ՀԱՅ'}</span>
          <img src={Arrow} alt="svg" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem
            onClick={() => handleLanguageChange("en")}
          >
            <p

              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              ENG
            </p
            >
          </MenuItem>
          <MenuItem
            onClick={() => handleLanguageChange("hy")}>
            <p
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              ՀԱՅ
            </p>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  )
}
