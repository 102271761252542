import classNames from "../../utils";
import GoTo from "../GoTo";
import { useTranslation } from "react-i18next";

const BlockTwo = ({ data }) => {
  const gridItemStyle = {
    backgroundImage: `url(${data?.biography_image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative'
  }

    const { t } = useTranslation(); // Hook for translations

    return (
    <div className="max-h-[720px] h-full">
      <div className="w-full h-full md:max-h-[720px] flex justify-end bg-right md:bg-center" style={gridItemStyle}>
        <div className="flex flex-col px-9 py-6 md:py-12 bg-[#2D2921A8] max-w-[720px] min-w-[400px] md:min-w-[600px]">
          <h1 className="text-subTitle text-2xl text-center md:text-start md:text-4xl font-bold font-custom"> {t("biography")} </h1>
          <p
            className={classNames(
              'myHtml pl-4 text-xl text-[#FFFFFF] max-h-[500px] overflow-y-scroll max-w-[485px] mt-6 md:mt-12 leading-10 font-customFamily font-normal'
            )}
            dangerouslySetInnerHTML={{ __html: data?.text || '' }}
          />
        </div>
      </div>
      <GoTo title={t('about_sargis')} to="/about"/>
    </div>
  )
}

export default BlockTwo